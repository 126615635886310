<template>
	<div>
		<div class="support">
			<div class="banner">
				<div class="title">
					{{ $t('support.title') }}
				</div>
				<div class="font1">
					{{ $t('support.font1') }}
				</div>
				<div class="font2">
					{{ $t('support.font2') }}
				</div>
				<div class="font2">
					{{ $t('support.font3') }}
				</div>
			</div>

			<div class="FAQ">
				<div class="FAQ_title">
					<img
						src="https://jade-sophisticated-cockroach-243.mypinata.cloud/ipfs/QmafcWSsa3GazhCLqr2yvWhcHDHZu8GrXkujkr4EWMMgwc"
						alt=""
					/>
					<span>
						{{ $t('support.icon_title') }}
					</span>
				</div>
				<div class="FAQ_body">
					<div class="FAQ_item" v-for="(item, index) in state.arrList" :key="index">
						<div class="title" @click="change(item)">
							<span>{{ item.title }}</span>
							<span class="color" v-show="!item.active">+</span>
							<span class="color" v-show="item.active">-</span>
						</div>
						<div class="content" v-show="item.active">
							<span>
								{{ item.content }}
							</span>
							<a href="https://app.voobank.com/#/mainPage" target="_blank"
								>https://app.voobank.com</a
							>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
import { ref, reactive } from 'vue';
import { submitSupport } from '@/api/index';
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();
const router = useRouter();
const activeName = ref([1]);
const accordion = ref(false); // 是否开启手风琴模式

const state = reactive({
	http: 'https://jade-sophisticated-cockroach-243.mypinata.cloud/ipfs/QmeUkox4nkg6joPFDuMa9d3Bmp5DQcqH4mKsZyUuF9mXar',
	arrList: [
		{
			title: '1. What is the Voobank Digital Asset Card and how does it work?',
			content:
				'The Voobank Digital Asset Card is a digital asset card that allows you to manage and spend cryptocurrencies easily. It links directly to your cryptocurrency wallet, enabling seamless transactions at various merchants. To learn more, please visit: ',
			active: false,
		},
		{
			title: '2. What types of cards are offered by Voobank?',
			content:
				'Voobank offers both virtual cards for online transactions and physical cards for in-store purchases. Each type is designed for different spending needs. To learn more, please visit: ',
			active: false,
		},
		{
			title: '3. How do I register for a Voobank account?',
			content:
				"To register, go to the Voobank website, click 'Sign Up,' and fill out the registration form with your personal details. You'll need to verify your email address to complete the process. To learn more, please visit: ",
			active: false,
		},
		{
			title: '4. What documents are needed for identity verification (KYC)?',
			content:
				"For KYC verification, you'll need a government-issued ID (like a passport or driver's license) and a document proving your address, such as a utility bill or bank statement. To learn more, please visit: ",
			active: false,
		},
		{
			title: '5. How can I apply for the Voobank Digital Asset Card?',
			content:
				'You can apply for the Digital Asset Card by logging into your Voobank account and navigating to the Digital Asset Card section. Follow the on-screen instructions to complete your application. To learn more, please visit: ',
			active: false,
		},
		{
			title: '6. What is the process for recharging my digital asset card?',
			content:
				"To recharge your card, log into your account, select the 'Recharge' option, choose the cryptocurrency you wish to use, enter the amount, and follow the prompts to complete the transaction. To learn more, please visit: ",
			active: false,
		},
		{
			title: '7. Which cryptocurrencies can I use to recharge my card?',
			content:
				'You can recharge your Voobank Digital Asset Card with major cryptocurrencies like Bitcoin, Ethereum, and other supported altcoins. Check the Voobank website for a complete list of accepted currencies. To learn more, please visit: ',
			active: false,
		},
		{
			title: '8. How do I check my transaction history on the Voobank Digital Asset Card?',
			content:
				"To check your transaction history, log into your Voobank account and navigate to the 'Transactions' section in your Digital Asset Card dashboard. This will provide a detailed history of all your transactions. To learn more, please visit: ",
			active: false,
		},
		{
			title: '9. What should I do if I encounter issues during the application process?',
			content:
				'If you face issues, consult the FAQ section on the Voobank website for common problems. If your issue persists, contact customer support for assistance. To learn more, please visit: ',
			active: false,
		},
		{
			title: '10. How long does the identity verification process take?',
			content:
				'The identity verification process typically takes between 1 to 5 minutes, but it may take longer during peak times due to high demand. To learn more, please visit: ',
			active: false,
		},
	],
});
const change = (item) => {
	item.active = !item.active;
	console.log(item);
};
</script>

<style scoped lang="scss">
@media screen and (min-width: 768px) {
	.support {
		width: 100%;
		background: rgba(0, 0, 0, 1);
		color: #ffffff;
		.banner {
			width: 100%;
			height: 730px;
			background: url('https://jade-sophisticated-cockroach-243.mypinata.cloud/ipfs/Qmbc6LmBFNiP3rBXKoUUJayg5hF5qeH4YRdenzfU2n9Yhq')
				no-repeat;
			background-size: 1920px 730px;
			padding: 0 360px 0;
			display: flex;
			flex-direction: column;
			align-items: center;
			.title {
				margin: 150px auto 18px;
				font-weight: 500;
				font-size: 50px;
				color: rgba(202, 130, 50, 1);
				line-height: 58px;
			}
			.font1 {
				font-weight: 500;
				font-size: 50px;
				line-height: 58px;
				margin: 220px 0 30px;
			}
			.font2 {
				font-weight: 400;
				font-size: 22px;
				line-height: 30px;
			}
		}
		.FAQ {
			width: 100%;
			padding: 0 360px 240px;
			.FAQ_title {
				display: flex;
				align-items: center;
				justify-content: center;
				margin-top: 150px;
				font-weight: 400;
				font-size: 56px;
				line-height: 70px;
				margin-bottom: 76px;
				img {
					width: 31px;
					height: 31px;
					margin-right: 6px;
				}
			}
			.FAQ_body {
				// display: flex;
				width: 100%;
				// height: 100vh;
				.FAQ_item {
					width: 100%;
					min-height: 61px;
					background: #0d0d0d;
					padding: 17px 20px 0;
					border-radius: 12px;
					margin-bottom: 16px;
					cursor: pointer;
					.title {
						width: 100%;
						font-size: 18px;
						font-weight: 400;
						display: inline-flex;
						justify-content: space-between;
						align-items: center;
						.color {
							color: #986226;
							font-size: 24px;
						}
					}
					.content {
						margin-top: 10px;
						padding: 0 8px 17px;
						height: auto;
						color: #ababab;
					}
				}
			}
		}
	}
}

@media screen and (max-width: 768px) {
	.support {
		width: 100%;
		background: rgba(0, 0, 0, 1);
		color: #ffffff;
		.banner {
			width: 100%;
			// height: 730px;
			background: url('https://jade-sophisticated-cockroach-243.mypinata.cloud/ipfs/Qmbc6LmBFNiP3rBXKoUUJayg5hF5qeH4YRdenzfU2n9Yhq')
				no-repeat;
			background-size: 375px 300px;
			padding: 0 32px 0;
			display: flex;
			flex-direction: column;
			align-items: center;
			text-align: center;
			.title {
				display: none;
				margin: 100px auto 18px;
				font-weight: 500;
				font-size: 28px;
				color: rgba(202, 130, 50, 1);
				line-height: 58px;
			}
			.font1 {
				font-weight: 500;
				font-size: 22px;
				line-height: 28px;
				margin: 150px 0 30px;
			}
			.font2 {
				font-weight: 400;
				font-size: 12px;
				line-height: 14px;
			}
		}
		.FAQ {
			width: 100%;
			padding: 150px 26px 120px;
			font-size: 12px;
			.FAQ_title {
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				font-weight: 600;
				font-size: 28px;
				line-height: 35px;
				margin-bottom: 76px;
				text-align: center;
				img {
					width: 12px;
					height: 12px;
					margin-right: 6px;
				}
			}
			.FAQ_body {
				width: 100%;
				.FAQ_item {
					width: 100%;
					min-height: 61px;
					background: #0d0d0d;
					padding: 10px 20px;
					border-radius: 12px;
					margin-bottom: 16px;
					cursor: pointer;
					font-size: 14px;
					color: #f5f5f8;
					.title {
						width: 100%;
						font-size: 18px;
						font-weight: 400;
						display: inline-flex;
						justify-content: space-between;
						align-items: center;
						.color {
							color: #986226;
							font-size: 24px;
						}
					}
					.content {
						margin-top: 10px;
						padding: 0 8px 17px;
						height: auto;
						color: #ababab;
					}
				}
			}
		}
	}
}
</style>
